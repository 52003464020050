* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Prompt', sans-serif;
}

a {
  text-decoration: none;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: royalblue;
  outline: 1px solid slategrey;
  border: none;
}

.thick-4 {
  background: linear-gradient(#fff 0 0) left / var(--d, 0%) 100% no-repeat,
    linear-gradient(#fff 0 0) right / var(--d, 0%) 100% no-repeat;
  transition: 0.5s;
}
.thick-4:hover {
  --d: 100%;
  color: black;
  transform: scale(1.2);
}

.allsides-6 {
  background: linear-gradient(currentColor 0 0) 0 0,
    linear-gradient(currentColor 0 0) 0 0,
    linear-gradient(currentColor 0 0) 0 100%,
    linear-gradient(currentColor 0 0) 0 100%,
    linear-gradient(currentColor 0 0) 100% 0,
    linear-gradient(currentColor 0 0) 100% 0,
    linear-gradient(currentColor 0 0) 100% 100%,
    linear-gradient(currentColor 0 0) 100% 100%;
  background-size: var(--d, 0) 3px, 3px var(--d, 0);
  background-repeat: no-repeat;
  transition: 0.5s;
}
.allsides-6:hover {
  --d: 20px;
  color: white;
}

.fancy-3 {
  width: 75px;
  padding: 10px;
  color: white;
  background: radial-gradient(circle, royalblue 50%, transparent)
      center/var(--d, 0) var(--d, 0) no-repeat,
    linear-gradient(white 0 0) bottom -12px left 50% /15px 15px no-repeat;
  transition: 0.3s, background-position 0.3s 0.3s;
}
.fancy-3:hover {
  transition: 0.3s, background-size 0.4s 0.3s, color 0.3s 0.3s;
  background-position: center;
  --d: 180%;
  color: white;
}

/* font-family: 'Hind Siliguri', sans-serif;
font-family: 'Prompt', sans-serif; 
font-family: 'Hind Madurai', sans-serif;
font-family: 'Dancing Script', cursive;*/
